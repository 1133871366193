
import { defineComponent, ref } from 'vue';

import type { UploadProps } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';

import MediaCards from '@/components/cards/MediaCards.vue';
import TableList from '@/components/tables/TableList.vue';
import axios from '@/utils/axios';
import { getVideoUrl } from '@/utils/mediaUrl';
const columns = [
  {
    title: 'text',
    dataIndex: 'text',
    key: 'text',
    isList: true,
    width: 200,
  },
  {
    title: 'Audio',
    dataIndex: 'mediaUrl',
    key: 'mediaUrl',
    width: 200,
  },
  {
    title: 'Scenario',
    dataIndex: 'scenario',
    key: 'scenario',
    width: 200,
  },
];

export default defineComponent({
  name: 'UploadImage',
  components: {
    TableList,
    MediaCards,
  },
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
    scenarioUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const imageFileList = ref<UploadProps['fileList']>([]);
    const mediaCardRef = ref();
    const tableListRef = ref();
    const text = ref('');

    const onChangeText= (txt: string) => {
      text.value = txt;
    }

    const onClick = async () => {
      const { scenario } = await mediaCardRef.value.submit();
      // const test = await mediaCardRef.value.submit();
      console.log(props);

      const { status } = await axios.post(props.apiUrl, {
        text: text.value,
        scenarioId: scenario.uid,
      });
      if (200 <= status && status < 300) {
        Modal.info({
          title: '요청 성공',
          content: '요청 성공했어요. 조금만 기다려보세요',
        });
        tableListRef.value.getApiData();
      } else {
        Modal.error({
          title: '요청 실패',
          content: '다시 한번 요청해보세요',
        });
      }
    };

    return {
      text,
      columns,
      mediaCardRef,
      tableListRef,
      imageFileList,
      getVideoUrl,
      onClick,
      onChangeText,
    };
  },
});
